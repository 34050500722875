<template>
  <div class="notation-form-view" v-if="fields.length > 0">
    <template v-for="(field, index) in fields" :key="index">
      <div class="notation-form-row">
        <div class="notation-form-col">
          <template v-if="types.includes(varType)">

            <Input v-if="renderComponent" inline strong-focus tab-index="1" :id="`field-${field.euid}`" :class="`field-${field.euid}`"
              :globalError="(field.error === true)" :modelValue="varType === 'DECIMAL' ? field.valeur : field.valeurautre"
              @update:modelValue="setField($event, field)"
              :label="(field.organe_id || frame.groupe === 'ECHANTILLON')  ? `Échantillon #${index + 1}` : 'Valeur'" :options="{
                min: variable?.variable?.valeur_min || variable?.valeur_min,
                max: variable?.variable?.valeur_max || variable?.valeur_max,
                step: 0.01
              }" :type="getType(varType)" :input-after="variable?.unite?.valeur" :textError="field?.errorMsg"
              @vnodeMounted="index === 0 && veIndex ==0 ? forceFocus(`#field-${field.euid}`) : null"
              />

          </template>

          <template v-if="varType === 'LIST'">
            <SelectExtended  :id="`field-${field.euid}`" :modelValue="getListValue(field.valeur_liste_id)" inline option-key="id" option-value="designation" label="Valeur" :items="variable.valeurs"
              tab-index="1" @update:modelValue="setField($event, field)" />
          </template>

          <template v-if="varType === 'BOOLEAN'">
            <Radio :id="`field-${field.euid}`" :modelValue="field.valeurautre" :items="[
              { label: 'Oui', value: true },
              { label: 'Non', value: false },
            ]" @update:modelValue="setField($event, field)" label="Valeurs" inputStyle="inline" tab-index="1" />
          </template>
        </div>
        <div class="notation-form-col-na">
          <Checkbox :modelValue="field.nonapplicable ? true : false" @update:modelValue="setCheckbox($event, field)"
            :id="`echantillon-checkbox-${field.euid}`" :items="[{ value: true }]" inline label=" " />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
/* eslint no-param-reassign: "error" */

import SelectExtended from '@/components/form/SelectExtended.vue'
import Radio from '@/components/form/Radio.vue'
import Input from '@/components/form/Input.vue'
import Checkbox from '@/components/form/Checkbox.vue'

export default {
  name: 'NotationForm',

  components: {
    Checkbox,
    Input,
    Radio,
    SelectExtended,
  },

  props: {
    frame: {
      type: Object,
    },
    variable: {
      type: Object,
    },
    organe: {
      type: Object,
    },
    frameIndex: {
      type: Number,
    },
    veIndex: {
      type: Number,
    },
  },

  data(props) {
    return {
      fields: [],
      notes: [],
      checkbox: [],
      types: ['DECIMAL', 'DATE', 'VARCHAR'],
      componentKey: 0,
      renderComponent: true,
      varType: props.variable?.type ? props.variable?.type?.uid : props.variable?.variable?.type.uid,
    }
  },

  watch: {
    async frame() {
      this.reloadComponent()
      await this.forceRerender()
    },
  },
  created() {
    this.reloadComponent()
  },
  methods: {
    setField(rawValue, field) {
      field.error = false
      field.errorMsg = null

      const value = this.sanitizeEvent(rawValue)

      if (value !== null) {
        if (
          (
            this.variable?.variable?.valeur_min && Number(value) < Number(this.variable?.variable?.valeur_min))
            || (this.variable?.variable?.valeur_max && Number(value) > Number(this.variable?.variable?.valeur_max)
            )
          || (
            this.variable?.valeur_min && Number(value) < Number(this.variable?.valeur_min))
            || (this.variable?.valeur_max && Number(value) > Number(this.variable?.valeur_max)
            )
        ) {
          field.error = true
          if (this.variable?.variable?.valeur_min) {
            field.errorMsg = `La valeur doit être comprise entre ${this.variable?.variable?.valeur_min} et ${this.variable?.variable?.valeur_max}`
          }
          if (this.variable?.valeur_min) {
            field.errorMsg = `La valeur doit être comprise entre ${this.variable?.valeur_min} et ${this.variable?.valeur_max}`
          }
        }
      }

      if (this.variable.type.uid === 'DECIMAL') {
        field.valeur = value
      } else if (this.variable.type.uid === 'LIST') {
        field.valeur_liste_id = value
      } else {
        field.valeurautre = value
      }

      this.notationService.setNote(field, this.$route.params.id)
      this.emitter.emit('form-updated', {})
    },
    setCheckbox(value, field) {
      field.nonapplicable = value
      this.notationService.setNote(field, this.$route.params.id)
      this.emitter.emit('form-updated', {})
    },
    getType(type) {
      switch (type) {
        case 'VARCHAR':
        default:
          return 'text'
        case 'DECIMAL':
          return 'number'
        case 'DATE':
          return 'date'
      }
    },

    getListValue(id = null) {
      if (id) {
        const localValue = this.variable.valeurs.find((val) => (val.id === id))
        return { key: localValue.id, value: localValue.designation }
      }
      return null
    },

    reloadComponent() {
      this.notes = []
      this.fields = []

      const localFields = this.notationService.getFieldsForFrame(this.frame, this.variable)
      this.notes = this.notationService.getNotesForFrame(this.$route.params.id, this.frame)

      localFields.forEach((field) => {
        const localNote = this.notes.find((note) => note.euid === field.euid)
        if (localNote) {
          this.fields.push(localNote)
        } else {
          this.fields.push(field)
        }
      })
      this.emitter.emit('form-updated', { action: 'reloadComponent' })
    },
    sanitizeEvent(event) {
      let value
      if (event !== null) {
        if (this.variable.type.uid === 'LIST') {
          value = event.key
        } else if (this.variable.type.uid === 'BOOLEAN') {
          value = event
        } else {
          value = event
        }

        if (value === '') {
          value = null
        }

        return value
      }

      return null
    },
    async forceRerender() {
      // Remove MyComponent from the DOM
      this.renderComponent = false

      // Wait for the change to get flushed to the DOM
      await this.$nextTick()

      // Add the component back in
      this.renderComponent = true
    },
    forceFocus(id) {
      const element = document.querySelector(id)
      if (element) {
        element.focus()
      }
    },
  },
}
</script>

<style scoped lang="scss">
// Notation main forms
.notation-form-row {
  display: flex;
  margin-bottom: $gutter-half;
}

.notation-form-col {
  flex-grow: 1;
}

.notation-form-col-na {
  min-width: 10rem;

  ::v-deep(.input-block) {
    justify-content: center;
  }

  ::v-deep(.checkbox) {
    justify-content: center;
  }

  ::v-deep(.label) {
    display: none;
  }

  .notation-form-row--header & {
    text-align: center;
    line-height: $line-height-small;
  }
}

.input-block--error {
  flex-wrap: wrap;
  justify-content: flex-end;

  ::v-deep(.input-text--error) {
    flex-basis: 100%;
  }
}
</style>
