<template>
  <div class="view">
    <div class="view-header">
      <div class="view-header__header">
        <Btn text="Sauvegarder et quitter" icon="arrow-left" class="notation-back-btn" :to="{
          name: 'home',
        }" />
        <Btn text="Synchroniser" color="secondary" @click="syncData()" :disabled="!isOnline" />
        <Btn text="Terminer la notation" color="primary" :to="goToSummary('data')" />
      </div>
      <!-- <div class="view-header__footer">
        <h1 class="page-title">Notation - Désignation de la notation</h1>
      </div> -->
      <div class="view-header__notation-tabs">
        <div class="notation-tabs">
          <router-link v-show="notation?.frames?.terrain
            ? Object.keys(notation?.frames?.terrain)?.length > 0
            : false
            " @click="getFrame()" class="notation-tab" :class="{ active: getLocalization() === 'terrain' }" :to="{
              name: 'notation',
              params: {
                id: getId(),
                localisation: 'terrain',
                etape: notation?.frames?.terrainIndex,
                notationProps: JSON.stringify(notation),
              },
            }">
            <span>Sur le terrain</span>
          </router-link>
          <router-link v-show="notation?.frames?.labo
            ? Object.keys(notation?.frames?.labo)?.length > 0
            : null
            " @click="getFrame()" class="notation-tab" :class="{ active: getLocalization() === 'labo' }" :to="{
              name: 'notation',
              params: {
                id: getId(),
                localisation: 'laboratoire',
                etape: notation?.frames?.laboIndex,
                notationProps: JSON.stringify(notation),
              },
            }">
            <span>En laboratoire</span>
          </router-link>
        </div>
      </div>
    </div>

    <div class="view-body">
      <div class="notation-view-body-header">
        <div class="notation-index">
          <div class="notation-index__current">{{ metas.step }}</div>
          <div class="notation-index__total">{{ metas.total }}</div>
        </div>
        <div class="notation-metas">
          <div class="notation-meta">
            <div class="notation-meta__label">Répétition</div>
            <div class="notation-meta__value">
              {{
                frame?.microparcelle?.repetition && !notation.aveugle
                  ? frame?.microparcelle?.repetition
                  : '—'
              }}
            </div>
          </div>
          <div class="notation-meta">
            <div class="notation-meta__label">N° modalité</div>
            <div class="notation-meta__value">{{ displayModalite }}
            </div>
          </div>
          <div class="notation-meta">
            <div class="notation-meta__label">Modalité</div>
            <div class="notation-meta__value">{{ displayModaliteName }}
            </div>
          </div>
          <div class="notation-meta">
            <div class="notation-meta__label">Regroupé par</div>
            <div class="notation-meta__value">{{ metas.group ?? "—" }}</div>
          </div>
          <div class="notation-meta">
            <div class="notation-meta__label">Organe</div>
            <div class="notation-meta__value">
              {{ displayOrganes }}
            </div>
          </div>
        </div>
        <div class="notation-micro-parcel-coordinates">
          <div class="micro-parcel-coordinates__label">
            Numéro géographique<br />
            de la micro-parcelle
          </div>
          <div class="micro-parcel-coordinates__value">
            {{
              frame?.microparcelle?.coordonnees
                ? frame?.microparcelle?.coordonnees
                : '---'
            }}
          </div>
        </div>
      </div>
      <FrameView v-if="frame" :frame="frame" :notation="notation" />
    </div>

    <div class="view-footer">
      <div class="notation-progress">
        <div class="notation-progress-label">Saisie complétée à&nbsp;:</div>
        <div class="notation-progress-bar">
          <div class="notation-progress-bar-line" :style="{
            width: `${notationProgress}%`,
          }"></div>
          <div class="notation-progress-bar-number" :style="{
            left: `${notationProgress}%`,
          }">
            {{ notationProgress }}%
          </div>
        </div>
      </div>
    </div>

    <Btn :disabled="metas.step === 1" class="notation-btn notation-btn--previous" color="white" icon="caret-left" round
      @click="previousStep()" />
    <Btn class="notation-btn notation-btn--next" color="white" icon="caret-right" round @click="goNext()" />
  </div>

  <teleport to="#topbar-help-button">
    <Btn class="btn-toggle-tool-panel" round grow icon="question-circle" iconSize="lg"
      @click="keyboardNavigationModal = !keyboardNavigationModal" />
  </teleport>

  <Modal title="Notation - Navigation au clavier" :active="keyboardNavigationModal"
    @modal-close="keyboardNavigationModal = false">
    <template v-slot:modal-body>
      <p>
        <em>Les raccourcis claviers suivants sont utilisables pour faciliter la
          saisie des notations&nbsp;:</em>
      </p>
      <p>&nbsp;</p>
      <h4>Changer d'étape</h4>
      <p>
        Étape suivante&nbsp;: touche <span class="keycap">Entrée&nbsp;↲</span>
      </p>
      <p>
        Étape précédente&nbsp;: touches
        <span class="keycap">Maj&nbsp;⇧</span> et
        <span class="keycap">Entrée&nbsp;↲</span>
      </p>
      <p>&nbsp;</p>
      <h4>Naviguer dans les formulaires</h4>
      <p>Champ suivant&nbsp;: touche <span class="keycap">Tab&nbsp;⇆</span></p>
      <p>
        Champ précédent&nbsp;: touches <span class="keycap">Maj&nbsp;⇧</span> et
        <span class="keycap">Tab&nbsp;⇆</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <em>Note&nbsp;: cette fenêtre d'information peut être rouverte avec le
          bouton d'aide ( <SvgIcon name="question-circle" size="sm"></SvgIcon> )
          du menu supérieur.</em>
      </p>
    </template>
    <template v-slot:modal-footer>
      <Btn text="Fermer" @click="keyboardNavigationModal = false" color="primary" />
    </template>
  </Modal>
  <ProgressModal />
</template>

<script>
import FrameView from '@/views/notations/FrameView.vue'
import Btn from '@/components/base/Btn.vue'
import SvgIcon from '@/components/base/SvgIcon.vue'
import Modal from '@/components/layout/Modal.vue'
import ProgressModal from '@/views/componentsViews/notation/ProgressModal.vue'

export default {
  name: 'NotationView',
  components: {
    Btn,
    SvgIcon,
    FrameView,
    Modal,
    ProgressModal,
  },
  props: {
    notationProps: {
      type: String,
    },
    id: {},
    etape: {},
    localisation: {},
    pageTitle: {},
  },

  data() {
    return {
      isOnline: navigator.onLine,
      notation: {},
      reservation: {},
      frame: null,
      metas: {
        step: null,
        total: null,
        group: null,
        organe: null,
      },
      notationProgress: 0,
      keyboardNavigationModal: false,
    }
  },
  computed: {
    displayModalite() {
      if (this.notation.aveugle) {
        return '—'
      }
      if (this.frame?.modalite?.ordre) {
        return this.frame?.modalite?.ordre
      }
      if (this.frame?.microparcelle?.modalite?.ordre) {
        return this.frame?.microparcelle?.modalite?.ordre
      }
      return '—'
    },
    displayModaliteName() {
      if (this.notation.aveugle) {
        return '—'
      }
      if (this.frame?.modalite?.ordre) {
        return this.frame?.modalite?.designation
      }
      if (this.frame?.microparcelle?.modalite?.designation) {
        return this.frame?.microparcelle?.modalite?.designation
      }
      return '—'
    },
    displayOrganes() {
      const variables = this.frame?.variables_etudiees?.filter(
        (ve) => ve.organes !== null,
      )
      if (variables !== undefined && variables.length > 0) {
        return variables
          .map((ve) => ve.organes.map((organe) => organe.designation))
          .join(',')
      }
      return '—'
    },
  },
  watch: {
    '$route.params.localisation': {
      deep: true,
      handler() {
        if (typeof this.$route.params.localisation !== 'undefined') {
          this.getFrame()
        }
      },
    },
    'notation.frames': {
      deep: true,
      handler() {
        this.setProgression()
      },
    },
  },

  mounted() {
    this.emitter.on('form-updated', this.formUpdated)

    this.emitter.emit('open-loader')

    this.getNotation().then(() => {
      this.emitter.emit('close-loader')
    })

    // Global keyboard bindings
    window.addEventListener('keydown', this.managekey)

    // If first time, display help modal
    if (!localStorage.getItem('firstNotationHelp')) {
      this.keyboardNavigationModal = true
      localStorage.setItem('firstNotationHelp', true)
    }
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.managekey)
  },
  methods: {
    managekey(e) {
      if (e.getModifierState('Shift') && e.key === 'Enter') {
        if (this.metas.step > 1) {
          this.previousStep()
        }
      } else if (e.key === 'Enter') {
        if (this.metas.step <= this.metas.total) {
          this.goNext()
        }
      }
    },
    /** **********************************************
     * Getters
     ********************************************** */
    /**
     * Get notation from props or localStorage
     *
     * Called only at mounted
     */
    getNotation() {
      return new Promise((nextStep) => {
        this.frame = []
        this.reservation = this.notationService.getItem(
          'reservations',
          this.$route.params.id,
        )
        if (this.notationProps) {
          this.notation = JSON.parse(this.notationProps)
        } else {
          this.notation = this.reservation.notation
        }
        this.setIndex()
        this.setProgression()
        this.getFrame()
        // this.setAverage()
        nextStep()
      })
    },
    /**
     * Get the frame
     *
     * Can be terrain or labo
     */
    getFrame() {
      // Get the frame (terrain: [] or labo: []) contains in micro-parcelle
      this.frame = this.notation.frames[this.getLocalization()][this.getStep('frame')]
      this.setMetas()
    },
    /**
     * Get current active tab
     * @param indexName
     * @returns {{active: boolean}}
     */
    getActiveTab(indexName) {
      return {
        active: this.$route.params.localisation === indexName,
      }
    },
    /**
     * Get localisation from route params
     * Can be terrain or laboratoire
     * @returns string
     */
    getLocalization() {
      if (this.$route.params.localisation === 'laboratoire') {
        return 'labo'
      }
      return this.$route.params.localisation
    },
    /**
     * Get id from route params
     * @returns string
     */
    getId() {
      return parseInt(this.$route.params.id, 10)
    },
    /**
     * Return step
     * @param type: ['frame', 'route']
     * @param stepType ['previous', 'current', 'next']
     */
    getStep(type, stepType) {
      const routeStep = () => {
        const rp = parseInt(this.$route.params.etape, 10)
        switch (stepType) {
          case 'previous':
            return rp - 1
          case 'current':
          default:
            return rp ?? 1
          case 'next':
            return rp + 1
        }
      }
      if (type === 'frame') {
        return routeStep() - 1
      }
      return routeStep()
    },
    getProgression() {
      return this.notationProgress
    },

    /** **********************************************
     * Setters
     ********************************************** */
    /**
     * Set index if the terrain or labo index is equal to 0
     */
    setIndex() {
      if (this.notation.frames.terrainIndex === 0) {
        this.notation.frames.terrainIndex = this.getStep('route')
      }
      if (this.notation.frames.laboIndex === 0) {
        this.notation.frames.laboIndex = this.getStep('route')
      }
    },
    /**
     * Set the metas to get total of frames, group, and current step
     * @param stepType
     */
    setMetas(stepType = 'current') {
      this.metas = {
        step: this.getStep('route', stepType),
        total: Object.keys(this.notation.frames[this.getLocalization()]).length,
        group:
          this.notation.frames[this.getLocalization()][
            this.getStep('frame', stepType)
          ]?.groupe,
      }

      if (this.metas.step > this.metas.total) {
        this.metas.step = this.metas.total
        this.goToSummary('route')
      }
    },
    /**
     * Set the progression
     */
    setProgression() {
      let storedCompletion = 0

      const notes = this.notationService.getNotes(this.getId())
      storedCompletion = notes.length
      const total = this.notation?.frames?.valeurs

      this.notationProgress = Math.floor((storedCompletion / total) * 100)

      // eslint-disable-next-line
      this.reservation.progression = this.notationProgress;
      // eslint-disable-next-line
      this.reservation.notation.progression = this.notationProgress;

      this.notationService.setItem('reservations', this.reservation)
    },

    setAverage() {
      const frames = this.notation.frames[this.getLocalization()]

      frames.forEach((frame) => {
        let notesVariable = []

        // eslint-disable-next-line
        notesVariable = this.notationService.getNotesForFrame(this.$route.params.id, frame)

        frame.variables_etudiees.forEach((ve) => {
          const variableType = ve.variable !== null ? ve.variable.type.uid : ve.type.uid

          const localNotes = notesVariable.filter((note) => note.variable_id === ve.id)

          let localAverage = this.calculateAverage(localNotes, variableType)
          let localStandardDeviation = this.calculateStandardDeviation(localNotes, variableType)

          if (frame.groupe === 'ORGANE' && ve.organes !== null) {
            ve.organes.forEach((organe) => {
              const notesOrgane = notesVariable

              if (notesOrgane.length > 0) {
                const organeAverage = this.calculateAverage(
                  notesOrgane,
                  ve?.variable?.type?.uid,
                )
                const organeStandardDeviation = this.calculateStandardDeviation(
                  notesOrgane,
                  ve?.variable?.type?.uid,
                )

                // eslint-disable-next-line
                organe.ecartType = organeStandardDeviation
                localStandardDeviation = organeStandardDeviation

                // eslint-disable-next-line
                organe.moyenne = organeAverage
                localAverage = organeAverage
              }
            })
          }
          // eslint-disable-next-line
          ve.moyenne = localAverage
          // eslint-disable-next-line
          ve.ecartType = localStandardDeviation
        })
      })
      // eslint-disable-next-line
      this.reservation.notation = this.notation;
      this.notationService.setItem('reservations', this.reservation)
    },
    /**
     * Called when values change inside the frame
     * @param event
     */
    formUpdated() {
      // eslint-disable-next-line
      this.reservation.notation = this.notation;
      this.notationService.setItem('reservations', this.reservation)

      this.setAverage()
      this.setProgression()
    },

    calculateStandardDeviation(valeurs, type) {
      const filtedValues = this.filterStatsValues(valeurs, type)
      let variance = null
      const average = this.calculateAverage(valeurs, type)
      if (filtedValues.length > 0 && average !== null) {
        variance = filtedValues
          .map((value) => (value - average) ** 2)
          .reduce((a, b) => a + b, 0) / (filtedValues.length - 1)

        variance = this.helperService.round(Math.sqrt(variance), 2)

        if (Number.isNaN(variance)) {
          variance = null
        }
      }
      return variance
    },

    calculateAverage(valeurs, type) {
      const filtedValues = this.filterStatsValues(valeurs, type)
      let average = null
      if (filtedValues.length > 0) {
        if (type === 'BOOLEAN') {
          const yes = filtedValues.filter((item) => (item === 'true'))
          const no = filtedValues.filter((item) => (item === 'false'))
          if (yes.length >= no.length) {
            return 'true'
          }
          return 'false'
        } if (type === 'DATE') {
          let totalms = 0
          filtedValues.forEach((item) => {
            const thedate = new Date(item).getTime()
            totalms += thedate
          })
          average = totalms / filtedValues.length
        } else {
          const total = filtedValues.reduce((a, b) => a + b)
          average = this.helperService.round(total / filtedValues.length, 2)
          if (Number.isNaN(average)) {
            average = null
          }
        }
      }
      return average
    },

    filterStatsValues(rawValus, type) {
      // on garde les valeur
      let valueListe = rawValus.filter(
        (valeur) => (valeur.valeurautre !== '' && valeur.valeurautre !== null)
          || (valeur.valeur !== '' && valeur.valeur !== null),
      )

      // on retire les nonapplicable
      valueListe = valueListe.filter((valeur) => (valeur.nonapplicable === false))

      // on retire les list
      valueListe = valueListe.filter((valeur) => (valeur.valeur_liste_id !== ''))

      const valeurautres = valueListe.map((valeur) => valeur.valeurautre)
      const values = valueListe.map((valeur) => parseFloat(valeur.valeur))

      if (type === 'DATE') {
        return valeurautres
      }
      if (type === 'BOOLEAN') {
        return valeurautres
      }

      return values
    },

    /** **********************************************
     * Process
     ********************************************** */
    handleChange(stepName) {
      this.getFrame()

      this.notation.frames[`${this.getLocalization()}Index`] = this.getStep(
        'route',
        stepName,
      )

      // eslint-disable-next-line
      this.reservation.notation = this.notation;
      this.notationService.setItem('reservations', this.reservation)

      this.setMetas(stepName)
      this.$emit('set-frame', this.frame)
    },
    /**
     * Used when user clicks in previous and next buttons
     * @param stepName
     */
    handleRoute(stepName) {
      this.$router.push({
        name: 'notation',
        params: {
          id: this.reservation.id,
          localisation: this.getLocalization(),
          etape: this.getStep('route', stepName),
          notationProps: JSON.stringify(this.notation),
        },
      })
    },
    previousStep() {
      this.forceBlur()
      this.handleChange('previous')
      this.handleRoute('previous')
    },
    nextStep() {
      this.handleChange('next')
      this.handleRoute('next')
    },
    /**
     * Call when "Synchroniser" button is triggered
     */
    syncData() {
      this.emitter.emit('open-loader')
      this.notationService.synchronizeNotation(this.getId()).then(() => {
        // this.getNotation()
        this.emitter.emit('close-loader')
      })
    },

    /** **********************************************
     * Helpers
     ********************************************** */
    /**
     * Get the data of summary page
     * Can be data for only return route data or route to make a redirect to this page
     * @param type ['data', 'route']
     */
    goToSummary(type) {
      const routeData = {
        name: 'notationSummary',
        params: {
          id: this.getId(),
        },
        query: {
          from: this.getLocalization(),
        },
      }

      return type === 'data' ? routeData : this.$router.push(routeData)
    },
    /**
     * Either go to the next step or to the summary
     */
    goNext() {
      this.forceBlur()
      if (this.metas.step >= this.metas.total) {
        this.metas.step = this.metas.total
        this.goToSummary('route')
      } else {
        this.nextStep()
      }
    },

    forceBlur() {
      const elements = document.querySelectorAll('.notation-form-view input')
      elements.forEach((element) => {
        element.blur()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
p {
  ::v-deep(.icon) {
    vertical-align: top;
  }
}
</style>
