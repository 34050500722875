<template>
  <div>
    <div class="notation-view-body-body">
      <template v-for="(ve, index) in frame.variables_etudiees" :key="index">
        <!-- HEADER -->
        <div class="notation-view-body-body-header">
          <div class="notation-form-title">
            <div class="notation-form-variable-name">{{ ve.designation }}</div>
            <div class="notation-form-target-name" v-show="ve?.cible?.valeur">
              Cible&nbsp;: <span>{{ ve?.cible?.valeur }}</span>
            </div>
          </div>
          <div class="notation-form-stats" v-if="frame.groupe">
            <div class="notation-form-stat">
              <div class="notation-form-stat__label">Moyenne</div>
              <div class="notation-form-stat__value">
              <template v-if="ve.type.uid == 'DATE'">
                {{ helperService.displayDate(ve.moyenne) ?? "—" }}
              </template>
              <template v-else>
                {{ ve.moyenne ?? "—" }}
              </template>
              </div>
            </div>
            <div class="notation-form-stat">
              <div class="notation-form-stat__label">Écart type</div>

              <div class="notation-form-stat__value">
                {{ ve.ecartType ?? "—" }}
              </div>
            </div>
          </div>
        </div>

        <!-- BODY | FORM -->
        <div class="notation-view-body-body-body">
          <!-- GROUPE === null -->
          <template v-if="ve?.saisie_niveau?.uid === 'MODALITE'">
            <div class="notation-form">
              <div class="notation-form-row notation-form-row--header">
                <div class="notation-form-col"></div>
                <div class="notation-form-col-na">
                  <span>Non<br />applicable</span>
                </div>
              </div>
              <NotationForm :frame="frame" :variable="ve" :frameIndex="index" :veIndex='index' />
            </div>
          </template>

          <template v-if="ve?.saisie_niveau?.uid === 'MICROPARCELLE'">
            <div class="notation-form">
              <div class="notation-form-row notation-form-row--header">
                <div class="notation-form-col"></div>
                <div class="notation-form-col-na">
                  <span>Non<br />applicable</span>
                </div>
              </div>
              <NotationForm :frame="frame" :variable="ve" :frameIndex="index" :veIndex='index' />
            </div>
          </template>

          <!-- GROUP === ECHANTILLON -->
          <template  v-if="ve?.saisie_niveau?.uid === 'ECHANTILLON'">
          <div class="notation-form"  v-for="(organe, i) in ve.organes"
            :key="i"
          >
            <div class="notation-form-row notation-form-row--header">
              <div class="notation-form-col"></div>
              <div class="notation-form-col-na">
                <span>Non<br />applicable</span>
              </div>
            </div>
            <NotationForm :frame="frame" :variable="ve" :organe="organe" :frameIndex="index" :veIndex='index' />
          </div>
          </template>

          <!-- GROUP = ORGANE -->
          <div
            class="notation-form"
            v-else-if="ve?.saisie_niveau?.uid === 'ORGANE'"
            v-for="(organe, i) in ve.organes"
            :key="i"
          >
            <div class="notation-form-row notation-form-row--header">
              <div class="notation-form-col">
                <h5>{{ organe.designation }}</h5>
              </div>
              <div class="notation-form-col-na">
                <span>Non<br />applicable</span>
              </div>
            </div>

            <NotationForm
              :frame="frame"
              :variable="ve"
              :frameIndex="index"
              :organe="organe"
              :veIndex='index'
            />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import NotationForm from '@/views/componentsViews/notation/NotationForm.vue'

export default {
  name: 'FrameView',

  components: {
    NotationForm,
  },

  props: {
    frame: {
      type: Object,
      default: undefined,
    },
    notation: {
      type: Object,
    },
  },

  data() {
    return {
      fields: [],
      checkbox: [],
      average: null,
      ecartType: null,
    }
  },
  methods: {
    /** **********************************************
     * Helpers
     ********************************************** */
    reloadComponent() {
      this.componentKey += 1
    },
  },
}
</script>

<style lang="scss" scoped>
// Main content

.notation-view-body-body {
  padding: $gutter;
  border-radius: $border-radius-base;
  @include shadow(1);
}

.notation-view-body-body-header {
  display: flex;
  gap: $gutter;
  align-items: flex-end;
  margin-bottom: $gutter;
  margin-top: -$gutter-half;
}

.notation-view-body-body-body + .notation-view-body-body-header {
  margin-top: $gutter;
}

.notation-form-title {
  margin-right: auto;
}

.notation-form-variable-name {
  font-size: $font-size-biggest;
  font-weight: $font-weight-black;
  line-height: $line-height-small;
  margin-bottom: $gutter-eighth;
}

.notation-form-target-name {
  font-size: $font-size-big;
  color: $color-gray;
  line-height: $line-height-small;

  > span {
    font-weight: $font-weight-black;
    color: $body-color-base;
    text-transform: uppercase;
  }
}

// Notation "real-time" stats

.notation-form-stats {
  display: flex;
  gap: $gutter-half;
}

.notation-form-stat {
}

.notation-form-stat__label {
  margin-bottom: $gutter-eighth;
  text-align: center;
  color: $color-gray;
}

.notation-form-stat__value {
  min-width: 8.4rem;
  padding: $gutter-quarter;
  text-align: right;
  background-color: $color-primary-lightest;
  border-radius: $border-radius-small;
  font-weight: $font-weight-semibold;
}

// Notation main forms

.notation-form {
  margin-bottom: $gutter-and-half;
}

.notation-form-row {
  display: flex;
  margin-bottom: $gutter-half;
}

.notation-form-col {
  flex-grow: 1;
}

.notation-form-col-na {
  min-width: 10rem;

  ::v-deep(.input-block) {
    justify-content: center;
  }

  ::v-deep(.checkbox) {
    justify-content: center;
  }

  ::v-deep(.label) {
    display: none;
  }

  .notation-form-row--header & {
    text-align: center;
    line-height: $line-height-small;
  }
}
</style>
